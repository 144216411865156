<template>
  <button @click="onClick" class="link-box">
    <div class="d-flex inner">
      <div class="flex-1 text">{{text}}</div>
      <div class="flex-0 rotate-right">
        <i class="icon-arrow-down"></i>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: 'LongLink',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick() {
      this.$emit('go');
    },
  },
};
</script>

<style lang="scss" scoped>
.link-box {
  color: $primary;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  width: 100%;
  max-height: calc(0.875rem + 30px);
  background: $white;
  box-shadow: 0px 2px 6px rgba(#000000, 0.06);
  padding: 0px 15px;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-align: left;
  letter-spacing: 0.1rem;

  // safari overrides
  margin: 0;

  .text {
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
  }

  .inner {
    height: 100%;
    width: 100%;
    padding: 10px 0px;
  }

  & + & {
    .inner {
      border-top: 1px solid rgba($color: #D8D8D8, $alpha: 0.7);
    }
  }

  .rotate-right {
    transform: rotate(-90deg);
  }
}
</style>
