const mapPricing = (pricing) => {
  const newSaleableItems = pricing.competition.saleableItems.filter(
    item => item.qty && item.qty > 0,
  );
  const newCompetition = Object.assign({}, pricing.competition, { saleableItems: newSaleableItems });

  return Object.assign({}, pricing, { competition: newCompetition });
};

module.exports = {
  mapPricing,
};
