<template>
  <div class="d-flex-column mw-md main">
    <h3 class="mb-0">Registration Summary</h3>
    <div class="checkout-card">
      <el-card class="full-width mt-1 align-text-center">
        <h3 class="no-margin">Team Registration</h3>
        <p class="no-margin">{{ current.entity ? current.entity.name : "" }}</p>
        <div class="d-flex checkout-item">
          <p class="no-margin capitalize">Registration Price</p>
          <div class="flex-last">
            {{ formatMoney(current.price ? current.price.totalPrice : 0) }}
          </div>
        </div>
        <div v-for="(item, index) in notEmptyAddons" :key="index" class="d-flex checkout-item">
          <p class="no-margin capitalize">{{ item.qty + " x " }} {{ item.name }}</p>
          <div class="flex-last">
            {{ formatMoney(item.qty * item.price) }}
          </div>
        </div>
        <div v-if="discount" class="d-flex checkout-item">
          <p class="no-margin capitalize">
            {{ discount.name }}
          </p>
          <div class="flex-last green">
            {{ formatMoney(discount.calculatedAmount) }}
          </div>
        </div>
        <div v-if="checkoutTotal" class="d-flex checkout-item total">
          <h4 class="no-margin">Total</h4>
          <div class="flex-last">{{ formatMoney(checkoutTotal) }}</div>
        </div>
      </el-card>
    </div>

    <el-card>
      <CheckBoxLine v-model="termsAccepted">
        <p>
          I understand that by registering I have agreed to the Terms and Conditions of
          participation.
        </p>
        <p class="message">
          By completing this team registration, you agree to abide by
          <a href="https://www.playrugbyleague.com/policies">the policies of NRL</a> and to access,
          use and/disclose information regarding registered participants only for the purposes of
          team administration and not to misuse or share any data contained within MySideline.
        </p>
      </CheckBoxLine>
    </el-card>

    <div v-if="checkoutTotal > 0">
      <div class="navigation-card-heading">Continue to payment</div>
      <LongLink text="Credit Card" @go="choose('creditcard')" />
      <LongLink v-if="!paymentReq" text="Complete Registration" @go="choose('paynotreq')" />
    </div>
    <div v-else>
      <div class="navigation-card-heading">No payment needed</div>
      <LongLink text="Complete Registration" @go="choose('none')" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatMoney } from "@/utils/money";
import LongLink from "@/components/LongLink.vue";
import CheckBoxLine from "@/components/form/fields/CheckBoxLine.vue";
import msg from "@/utils/constants/msg";
import { mapPricing } from "@/utils/registrationTeams";

export default {
  name: "TeamPayment",
  components: {
    LongLink,
    CheckBoxLine,
  },
  computed: {
    ...mapGetters("registrationTeams", ["current"]),
    paymentReq() {
      return (
        this.current.price && this.checkoutTotal > 0 && this.current.price.onlinePaymentRequired
      );
    },
    checkoutTotal() {
      // Calc Total Checkout Price
      let total = this.current.price ? this.current.price.pricing.competition.price : 0;

      if (
        this.current.price &&
        this.current.price.pricing.competition.discount &&
        this.current.price.pricing.competition.discount.calculatedAmount
      ) {
        total -= this.current.price.pricing.competition.discount.calculatedAmount;
      }

      total = Math.max(0, total);

      this.notEmptyAddons.forEach((d) => {
        total += d.price * (d.qty || 0);
      });

      return total;
    },
    notEmptyAddons() {
      const result = [];

      if (
        this.current.price &&
        this.current.price.pricing.competition.saleableItems &&
        this.current.price.pricing.competition.saleableItems.length > 0
      ) {
        this.current.price.pricing.competition.saleableItems.forEach((item) => {
          const { qty } = item;
          result.push({
            ...item,
            qty: qty || 0,
          });
        });
      }

      return result.filter((c) => c.qty && c.qty !== 0);
    },
    discount() {
      return this.current.price &&
        this.current.price.pricing.competition.discount &&
        this.current.price.pricing.competition.discount.calculatedAmount
        ? this.current.price.pricing.competition.discount
        : undefined;
    },
  },
  data() {
    return {
      formatMoney,
      Math,
      termsAccepted: false,
    };
  },
  methods: {
    choose(paymentMethod) {
      if (this.termsAccepted) {
        switch (paymentMethod) {
          case "creditcard":
            this.$router.push({ name: "checkout" });
            break;
          case "paynotreq":
            this.$store.commit("root/LOADING", true);
            this.$http
              .post("/nrl/api/v1/portal/registration-team/register-team", {
                answers: this.current.answers,
                competitionId: this.current.entity._id,
                ...this.current.teamDetails,
                pricing: mapPricing(this.current.price.pricing),
                paid: false,
                paymentTotal: this.checkoutTotal,
              })
              .then((response) => {
                const { teamId } = response.data.data;
                this.$store.dispatch("registrationTeams/clearCurrent");
                this.$store.commit("root/LOADING", false);
                if (teamId) {
                  this.$router.push({
                    name: "invitemembers",
                    query: { teamid: teamId, registered: "true" },
                  });
                } else {
                  this.$router.push({ name: "paymentcomplete" });
                }
              })
              .catch((error) => {
                this.$store.commit("root/LOADING", false);
                this.$store.commit("views/PUSH_NOTIFICATION", {
                  msg:
                    error.response && error.response.data && error.response.data.message
                      ? error.response.data.message
                      : msg.error.apiError,
                  type: "warning",
                });
                window.scrollTo(0, 0);
              });
            break;
          case "none":
            this.$store.commit("root/LOADING", true);
            this.$http
              .post("/nrl/api/v1/portal/registration-team/register-team", {
                answers: this.current.answers,
                competitionId: this.current.entity._id,
                ...this.current.teamDetails,
                pricing: mapPricing(this.current.price.pricing),
                paid: true,
                paymentTotal: 0,
              })
              .then((response) => {
                const { teamId } = response.data.data;

                this.$store.dispatch("registrationTeams/clearCurrent");
                this.$store.commit("root/LOADING", false);
                this.$router.push({ name: "paymentcomplete" });
                if (teamId) {
                  this.$router.push({
                    name: "invitemembers",
                    query: { teamid: teamId, registered: "true" },
                  });
                } else {
                  this.$router.push({ name: "paymentcomplete" });
                }
              })
              .catch((error) => {
                this.$store.commit("root/LOADING", false);
                this.$store.commit("views/PUSH_NOTIFICATION", {
                  msg:
                    error.response && error.response.data && error.response.data.message
                      ? error.response.data.message
                      : msg.error.apiError,
                  type: "warning",
                });
                window.scrollTo(0, 0);
              });
            break;
          default:
            this.$store.commit("views/PUSH_NOTIFICATION", {
              msg: msg.error.generic,
              type: "warning",
            });
        }
      } else {
        this.$store.commit("views/PUSH_NOTIFICATION", {
          msg: msg.error.acceptTerms,
          type: "warning",
        });
        window.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-card {
  hr {
    border: 0.5px solid rgba(#d8d8d8, 0.7);
  }

  ::v-deep .el-card__body {
    & > div {
      margin-top: 15px;
      padding-bottom: 10px;
    }
  }

  .checkout-item {
    border-bottom: 1px solid rgba(#d8d8d8, 0.7);

    &.total {
      margin-top: 0 !important;
      padding-top: 10px;
      border-bottom: none !important;
      border-top: 1px solid #222222;
    }
  }

  .red {
    color: red;
  }

  .green {
    color: $secondary;
  }

  .capitalize {
    text-transform: capitalize;
  }
}

.message {
  text-align: justify;
}
</style>
